import { setToken } from "../actions/user-action";
import { signOut, rehydrateUserIfChanged } from "./accountManagement";

const { NODE_ENV } = process.env;

export const parseJwt = (token) => {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
};

export const apiUri = (NODE_ENV === "development" ? `http://localhost:8001/api/v1` : `https://api.iloveitcamping.com/api/v1`)
//export const apiUri = (NODE_ENV === "development" ? `http://192.168.1.15:8001/api/v1` : `https://api.iloveitcamping.com/api/v1`)

export const _fetch = async (endPoint, method, data, useToken, accessToken = null) => {
  console.log("apiUri+endPoint=" + apiUri + endPoint);
  //console.log('method='+method)
  //console.log('data='+data)
  //console.log('useToken='+useToken)
  //console.log('accessToken='+accessToken)

  var headers = new Map();
  if (useToken) {
    headers.set("Authorization", "Bearer " + accessToken);
  }
  if (method === "POST" || method === "PATCH") {
    headers.set("Content-Type", "application/json");
  }
  //console.log('headers='+headers)

  var fetch_params = Object();
  fetch_params.method = method;
  fetch_params.headers = headers;

  if (method !== "GET") {
    //fetch_params.set('body', JSON.stringify(data))
    fetch_params.body = JSON.stringify(data);
  }

  return fetch(apiUri + endPoint, fetch_params)
    .then((response) => {
      //console.log('successful call to api')
      return response.json().then(function (data) {
        //console.log(data);
        var status = "error";
        var message = "";
        if (response.status === 200 || response.status === 202) {
          status = "success";
        } else if (response.status === 400) {
          status = "error";
          if (data.detail !== undefined) {
            message = data.detail;
          } else {
            message = "API Response Code ".concat(response.status);
          }
        } else if (response.status === 401) {
          status = "error";
          message = "Unauthorized Access";
        } else if (response.status === 403) {
          status = "error";
          message = "Forbidden Access";
        } else {
          status = "error";
          message = "API Response Code ".concat(response.status);
        }
        return {
          status: status,
          message: message,
          apiStatus: response.status,
          data: data,
        };
      });
    })
    .catch((error) => {
      console.error("api error:", error);
      return {
        status: "error",
        message: error,
        apiStatus: 0,
        data: "",
      };
    });
};

export const renewTokenIfNeeded = async (userData, store) => {
  //console.log('renewTokenIfNeeded: accessToken='+userData.accessToken);
  if (userData.accessToken !== undefined && userData.accessToken !== "") {
    //console.log('userData.accessTokenExpires='+userData.accessTokenExpires)
    var token_expires_in_minutes = (userData.accessTokenExpires - Date.now() / 1000) / 60;
    console.log("token_expires_in_minutes=" + token_expires_in_minutes);
    //var refresh_expires_in_minutes = (userData.refreshTokenExpires - (Date.now()/1000)) / 60
    //console.log('refresh_expires_in_minutes='+refresh_expires_in_minutes)
    if (token_expires_in_minutes < 15) {
      //if (token_expires_in_minutes < 59) {  // TODO: testing - put back  above line
      var refresh_expires_in_minutes = (userData.refreshTokenExpires - Date.now() / 1000) / 60;
      //console.log('refresh_expires_in_minutes='+refresh_expires_in_minutes)
      if (refresh_expires_in_minutes < 2) {
        //if (refresh_expires_in_minutes < 60) {  // TODO: testing - put back  above line
        console.log("expired!");
        //store.dispatch(reset()); //signout
        return { status: "expired", token: null };
      }
      //console.log('callin fetch to refresh token');
      return fetch(apiUri + `/users/refresh`, {
        method: "GET",
        headers: { Authorization: "Bearer " + userData.refreshToken },
      })
        .then((response) => response.json()) // parse JSON from request
        .then((resultData) => {
          console.log("returned from fetch to refresh token");
          //setAccessToken(resultData.access_token)
          //access_token = resultData.access_token
          //console.log('accessToken='+resultData.access_token)
          let parsed_token = parseJwt(resultData.access_token);
          console.log("token sub=" + parsed_token.sub);
          console.log("token exp=" + parsed_token.exp);

          store.dispatch(setToken(resultData.access_token, parsed_token.exp));
          console.log("after setToken");
          return { status: "renewed", token: resultData.access_token };
        })
        .catch((error) => {
          console.error("api error on token refresh:", error);
          return { status: "error", token: null };
        });
    } else {
      return { status: "valid", token: userData.accessToken };
    }
  } else {
    // user is not logged in
    //console.log('user not signed in');
    return { status: "not-signed-in", token: null };
  }
};

export const callAPI = async (endPoint, method, data, userData, store, useToken, skipRehydrate = false) => {
  if (useToken) {
    var localUserData = null;
    if (skipRehydrate) {
      console.log("skipping rehydrate");
      localUserData = { ...userData };
    } else {
      console.log("checking for rehydrate");
      localUserData = await rehydrateUserIfChanged(store, userData);
    }
    //console.log('localUserData='+localUserData.accessToken);
    //console.log('userData='+userData.accessToken);
    console.log('endPoint='+endPoint);
    var result = await renewTokenIfNeeded(localUserData, store);
    //console.log('after call to renewTokenIfNeeded result='+result);
    if (result.status === "expired") {
      //console.log('signing out')
      signOut(() => {});
      return { status: "signed-out", message: "User sign in has expired" };
    } else if (result.status === "not-signed-in") {
      return { status: "signed-out", message: "User is not signed in" };
    } else if (result.status === "error") {
      return { status: "error", message: "Unknown error" };
    }
    result = await _fetch(endPoint, method, data, true, result.token);
    return result;
  } else {
    return _fetch(endPoint, method, data, false);
  }
};
