import React, { useState, useEffect } from "react";

import { Form, InputGroup, Alert, Button } from "react-bootstrap";
import Logo from "../assets/logo-fire2.svg";
import ModalBody from "react-bootstrap/ModalBody";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalFooter from "react-bootstrap/ModalFooter";
import ModalTitle from "react-bootstrap/ModalTitle";
import { useSelector } from "react-redux";
import { store, persistConfig } from "../redux-store";
import { getStoredState } from "redux-persist";
import { setUser, updateUser, reset, rehydrate } from "../actions/user-action";
import { FaEye, FaEyeSlash, FaQuestionCircle } from "react-icons/fa";
import Popup from "reactjs-popup";
import { callAPI, parseJwt } from "./api";
import { navigate } from "gatsby";
import { FaTimes } from "react-icons/fa";

const Password = ({ formId }) => {
  const [showPassword, setShowPassword] = React.useState(false);

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <InputGroup className="xxmb-3">
      <Form.Control
        type={showPassword ? "text" : "password"}
        className="form-control"
        id="password"
        aria-describedby="passwordHelp"
      />
      <InputGroup.Text id="basic-addon1" onClick={toggleShowPassword}>
        {showPassword ? <FaEye /> : <FaEyeSlash />}
      </InputGroup.Text>
    </InputGroup>
  );
};

const LogoTitle = () => {
  return (
    <div className="d-flex justify-content-center flex-grow-1">
      <div className="d-flex">
        <Logo id="navbar-logo-account" width="40px" height="40px" />
        <div className="nav-logo-account-text ms-2">I Love It Camping</div>
      </div>
    </div>
  );
};

const getUserInfo = async (resultData, rememberMe) => {
  var newUserData = {};
  newUserData.accessToken = resultData.access_token;
  let parsed_token = parseJwt(resultData.access_token);
  newUserData.accessTokenExpires = parsed_token.exp;
  newUserData.refreshToken = resultData.refresh_token;
  parsed_token = parseJwt(resultData.refresh_token);
  newUserData.refreshTokenExpires = parsed_token.exp;

  var result = await callAPI("/users/me", "GET", null, newUserData, store, true, true);
  if (result.status === "success") {
    console.log("in getUserInfo roles=" + result.data.user_roles);
    store.dispatch(
      setUser(
        newUserData.accessToken,
        newUserData.refreshToken,
        newUserData.accessTokenExpires,
        newUserData.refreshTokenExpires,
        result.data.id,
        result.data.email,
        result.data.display_name,
        result.data.user_roles,
        result.data.is_verified,
        rememberMe,
        ""
      )
    );
    return "";
  } else {
    return result.message;
  }
};

export const refreshUserInfo = async (userData, store) => {
  const localUserData = await rehydrateUserIfChanged(store, userData);
  //console.log('refreshUserInfo  - userData.accessToken='+userData.accessToken);
  //console.log('refreshUserInfo  - localUserData='+localUserData.accessToken);
  var result = await callAPI("/users/me", "GET", null, localUserData, store, true);
  if (result.status === "success") {
    console.log("result.data.email=" + result.data.email);
    store.dispatch(updateUser(result.data.email, result.data.display_name, result.data.is_verified));
    return { status: "success", message: "", data: result.data };
  } else {
    return { status: "error", message: result.message, data: null };
  }
};

const validatePassword = (password) => {
  console.log("pw length=" + password.length);
  if (password.length < 8) {
    return false;
  }
  if (password.match(/[A-Z]/g) === null) {
    return false;
  }
  if (password.match(/[a-z]/g) === null) {
    return false;
  }
  if (password.match(/[0-9]/g) === null) {
    return false;
  }
  return true;
};

export const SignIn = ({ onSignIn, signUpClick, forgotPasswordClick, showLogo }) => {
  const userData = useSelector((state) => state.userData);
  const [bannerMessage, setBannerMessage] = useState("");

  const submitSignIn = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    const email = form.email.value;
    const password = form.password.value;
    const remember_me = form.remember_me.checked;
    let data = { email: email, password: password, remember_me: remember_me };
    setBannerMessage("");

    var result = await callAPI("/users/login", "POST", data, userData, store, false);
    if (result.status === "success") {
      console.log("calling getUserInfo");
      var error_message = await getUserInfo(result.data, remember_me);
      if (error_message !== "") {
        setBannerMessage("Error: " + error_message);
      } else {
        form.reset();
        console.log("after form reset");
        onSignIn();
      }
    } else {
      if (result.apiStatus === 400) {
        if (result.data.detail === "LOGIN_BAD_CREDENTIALS") {
          setBannerMessage("Invalid email address or password");
        } else {
          setBannerMessage(result.data.detail);
        }
      } else if (result.apiStatus === 401) {
        setBannerMessage("Invalid email address or password");
      } else {
        console.error("Error:", result.message);
        setBannerMessage("Error: " + result.message);
      }
    }
  };

  return (
    <>
      {showLogo ? <LogoTitle /> : <></>}
      {bannerMessage === "" ? (
        <></>
      ) : (
        <Alert className="ms-2 me-2 mt-2" variant="warning">
          {bannerMessage}
        </Alert>
      )}
      <ModalHeader>
        <ModalTitle>Sign In</ModalTitle>
      </ModalHeader>
      <ModalBody>
        <Form noValidate onSubmit={submitSignIn} id="signin-form">
          <div className="mb-3">
            <Form.Label htmlFor="exampleInputEmail1" className="form-label">
              Email address
            </Form.Label>
            <Form.Control required type="email" className="form-control" id="email" aria-describedby="emailHelp" />
            <div id="emailHelp" className="form-text">
              We'll never share your email with anyone else.
            </div>
          </div>
          <div className="mb-3">
            <Form.Label htmlFor="exampleInputPassword1" className="form-label">
              Password
            </Form.Label>
            <Password formId="signin-form" />
          </div>

          <div className="mb-3 form-check">
            <Form.Control type="checkbox" className="form-check-input me-2" id="remember_me" />
            <Form.Label className="form-check-label me-2" htmlFor="remember_me">
              {" "}
              Remember Me
            </Form.Label>
            <Popup
              trigger={
                <span>
                  <FaQuestionCircle />
                  &nbsp;
                </span>
              }
              position="right"
            >
              <div className="remember-me-help ms-1">Uncheck if using a public device</div>
            </Popup>
          </div>
          <button type="submit" className="btn btn-primary">
            Sign In
          </button>
        </Form>
      </ModalBody>
      <ModalFooter>
        <div className="d-flex justify-content-center flex-grow-1">
          <div className="d-flex flex-column">
            <div className="text-center">
              Don't have an account?{" "}
              <Button variant="link" className="local-link" onClick={signUpClick}>
                {" "}
                Sign Up
              </Button>
            </div>
            <div className="text-center">
              <Button variant="link" className="local-link" tabIndex={0} onClick={forgotPasswordClick}>
                Forgot password?
              </Button>
            </div>
          </div>
        </div>
      </ModalFooter>
    </>
  );
};

export const SignUp = ({ onSignUp, signInClick, showLogo }) => {
  const userData = useSelector((state) => state.userData);
  const [bannerMessage, setBannerMessage] = useState("");

  const submitSignUp = async (event) => {
    console.log("in signUp validation");
    console.log("here 1");
    event.preventDefault();
    console.log("here 2");
    const form = event.currentTarget;
    const email = form.email.value;
    const password = form.password.value;
    const remember_me = form.remember_me.value;
    console.log("form=" + form);
    console.log("form.email=" + email);

    if (!validatePassword(form.password.value)) {
      setBannerMessage("Password does not meet requirements listed below");
      console.log("pw bad");
    } else {
      console.log("pw good");
      let data = {
        email: email,
        password: password,
        remember_me: remember_me,
        registered_on_site: "iloveitcamping.com",
      };
      var result = await callAPI("/users/", "POST", data, userData, store, false);
      console.log("result=" + result);
      console.log("result.status=" + result.status);
      console.log("result.message=" + result.message);
      //console.log("data.detail=" + result.data.detail);
      if (result.status === "success") {
        var error_message = await getUserInfo(result.data, remember_me);
        if (error_message !== "") {
          setBannerMessage("Error: " + error_message);
        } else {
          form.reset();
          console.log("after form reset");
          onSignUp();
        }
      } else {
        if (result.apiStatus === 400) {
          if (result.data.detail === "REGISTER_USER_ALREADY_EXISTS") {
            setBannerMessage("Email is already registered");
          } else if (result.data.detail === "REGISTER_INVALID_PASSWORD") {
            setBannerMessage("Password does not meet security requirements");
          } else {
            setBannerMessage(result.data.detail);
          }
        } else {
          console.error("Error:", result.message);
          setBannerMessage("Error: " + result.message);
        }
      }
    }
  };

  return (
    <>
      {showLogo ? <LogoTitle /> : <></>}
      {bannerMessage === "" ? (
        <></>
      ) : (
        <Alert className="ms-2 me-2 mt-2" variant="warning">
          {bannerMessage}
        </Alert>
      )}
      <ModalHeader>
        <ModalTitle>Sign Up</ModalTitle>
      </ModalHeader>
      <ModalBody>
        <Form noValidate onSubmit={submitSignUp} id="signup-form" autoComplete="off">
          <div className="mb-3">
            Sign up to save your favorite recipes, get notifications about new recipes, and more!
          </div>
          <div className="mb-3">
            <Form.Label htmlFor="exampleInputEmail1" className="form-label">
              <b>Email address</b>
            </Form.Label>
            <Form.Control required type="email" className="form-control" id="email" aria-describedby="emailHelp" />
            <div id="emailHelp" className="form-text">
              We'll never share your email with anyone else.
            </div>
          </div>
          <div className="mb-3">
            <Form.Label htmlFor="exampleInputPassword1" className="form-label">
              <b>Password</b>
            </Form.Label>
            <Password formId="signup-form" />
            <div id="passwordHelp" className="form-text">
              <ul>
                <li>At least 8 characters</li>
                <li>At least 1 uppercase letter</li>
                <li>At least 1 lowercase letter</li>
                <li>At least 1 number</li>
              </ul>
            </div>
          </div>
          <div className="mb-3 form-check">
            <Form.Control type="checkbox" className="form-check-input me-2" id="remember_me" />
            <Form.Label className="form-check-label me-2" htmlFor="remember_me">
              {" "}
              Remember Me
            </Form.Label>
            <Popup
              trigger={
                <span>
                  <FaQuestionCircle />
                  &nbsp;
                </span>
              }
              position="right"
            >
              <div className="remember-me-help ms-1">Uncheck if using a public device</div>
            </Popup>
          </div>
          <button type="submit" className="btn btn-primary">
            Create Account
          </button>
        </Form>
      </ModalBody>
      <ModalFooter>
        <div className="d-flex justify-content-center flex-grow-1">
          <div className="d-flex flex-column">
            <div className="text-center">
              Have an account?{" "}
              <Button variant="link" className="local-link" onClick={signInClick}>
                Sign In
              </Button>
            </div>
          </div>
        </div>
      </ModalFooter>
    </>
  );
};

export const ForgotPassword = ({ onForgotPassword, signInClick, showLogo }) => {
  const userData = useSelector((state) => state.userData);
  const [bannerMessage, setBannerMessage] = useState("");
  const [emailSent, setEmailSent] = useState(false);

  const forgotPassword = async (event) => {
    console.log("in forgotPassword");
    event.preventDefault();

    var email = document.getElementById("email").value;
    if (email === "") {
      setBannerMessage("Enter your email address before continuing");
      return;
    }

    let data = { email: email };
    var result = await callAPI("/users/forgot-password", "POST", data, userData, store, false);
    console.log("result=" + result);
    console.log("result.status=" + result.status);
    console.log("result.message=" + result.message);
    if (result.status === "success") {
      setBannerMessage(
        "If the email you provided matches to an account, a reset password email will be sent. " +
          "The email should arrive within a few minutes. " +
          "Be sure to check your spam email folder if you do not see it."
      );
      setEmailSent(true);
    } else {
      console.error("Error:", result.message);
      setBannerMessage("Error: " + result.message);
    }
  };

  const onClickClose = () => {
    onForgotPassword();
  };

  return (
    <>
      {showLogo ? <LogoTitle /> : <></>}
      <ModalHeader>
        <ModalTitle>Forgot Password</ModalTitle>
      </ModalHeader>
      <ModalBody>
        {bannerMessage === "" ? (
          <></>
        ) : (
          <Alert className="ms-2 me-2 mt-2" variant="warning">
            {bannerMessage}
          </Alert>
        )}
        {emailSent ? (
          <Button className="btn btn-primary" onClick={onClickClose}>
            Close
          </Button>
        ) : (
          <Form onSubmit={forgotPassword} id="forgot-password-form">
            <div className="mb-3">
              <Form.Label htmlFor="exampleInputEmail1" className="form-label">
                Please enter the email you're using for your account.
              </Form.Label>
              <Form.Control required type="email" className="form-control" id="email" aria-describedby="emailHelp" />
            </div>
            <button type="submit" className="btn btn-primary">
              Continue
            </button>
          </Form>
        )}
      </ModalBody>
      <ModalFooter>
        <div className="d-flex justify-content-center flex-grow-1">
          <div className="d-flex flex-column">
            <div className="text-center">
              Know your password?{" "}
              <Button variant="link" className="local-link" onClick={signInClick}>
                Sign In
              </Button>
            </div>
          </div>
        </div>
      </ModalFooter>
    </>
  );
};

export const ChangePassword = ({ email, showLogo }) => {
  const userData = useSelector((state) => state.userData);
  const [emailSent, setEmailSent] = useState(false);
  const [bannerMessage, setBannerMessage] = useState("");

  const submitChangePassword = async (event) => {
    console.log("in submitChangePassword");
    event.preventDefault();

    let data = { email: email };
    var result = await callAPI("/users/forgot-password", "POST", data, userData, store, false);
    console.log("result=" + result);
    console.log("result.status=" + result.status);
    console.log("result.message=" + result.message);
    //console.log("data.detail=" + result.data.detail);
    if (result.status === "success") {
      setBannerMessage(
        "Reset password email has been sent. An email should arrive within a few minutes. " +
          "Be sure to check your spam email folder if you do not see it."
      );
      setEmailSent(true);
    } else {
      console.error("Error:", result.message);
      setBannerMessage("Error: " + result.message);
    }
  };

  return (
    <>
      {showLogo ? <LogoTitle /> : <></>}
      <ModalHeader>
        <ModalTitle>Change Password</ModalTitle>
      </ModalHeader>
      <ModalBody>
        {bannerMessage === "" ? (
          <></>
        ) : (
          <Alert className="ms-2 me-2 mt-2" variant="warning">
            {bannerMessage}
          </Alert>
        )}
        {emailSent ? (
          <></>
        ) : (
          <>
            <div className="mb-3">
              If you want to change your password, click the button below, and we will send password reset instructions
              to your email address.
            </div>

            <button type="button" className="btn btn-primary" onClick={submitChangePassword}>
              Reset Password
            </button>
          </>
        )}
      </ModalBody>
    </>
  );
};

export const ResetPassword = ({ token, showLogo }) => {
  const userData = useSelector((state) => state.userData);
  const [resetDone, setResetDone] = useState(false);
  const [bannerMessage, setBannerMessage] = useState("");

  const submitResetPassword = async (event) => {
    console.log("in submitChangePassword");
    event.preventDefault();
    const form = event.currentTarget;
    const password = form.password.value;
    console.log("form=" + form);

    if (!validatePassword(form.password.value)) {
      setBannerMessage("Password does not meet requirements listed below");
      console.log("pw bad");
    } else {
      console.log("pw good");
      let data = { token: token, password: password };
      console.log("data.token=" + data.token);
      var result = await callAPI("/users/reset-password", "POST", data, userData, store, false);
      console.log("result=" + result);
      console.log("result.status=" + result.status);
      console.log("result.message=" + result.message);
      //console.log("data.detail=" + result.data.detail);
      if (result.status === "success") {
        setBannerMessage("Password has been reset");
        setResetDone(true);
      } else {
        if (result.apiStatus === 400) {
          if (result.data.detail === "RESET_PASSWORD_BAD_TOKEN") {
            setBannerMessage("Invalid reset password link or link has expired.");
          } else if (result.data.detail === "REGISTER_INVALID_PASSWORD") {
            setBannerMessage("Password does not meet security requirements");
          } else {
            setBannerMessage(result.data.detail);
          }
        } else {
          console.error("Error:", result.message);
          setBannerMessage("Error: " + result.message);
        }
      }
    }
  };

  return (
    <>
      {showLogo ? <LogoTitle /> : <></>}
      <ModalHeader>
        <ModalTitle>Reset Password</ModalTitle>
      </ModalHeader>
      <ModalBody>
        {bannerMessage === "" ? (
          <></>
        ) : (
          <Alert className="ms-2 me-2 mt-2" variant="warning">
            {bannerMessage}
          </Alert>
        )}
        {resetDone ? (
          <>
            {isUserSignedIn(userData) ? (
              <></>
            ) : (
              <button type="button" className="btn btn-primary" onClick={navigate("/account/signin")}>
                Sign In
              </button>
            )}
          </>
        ) : (
          <Form noValidate onSubmit={submitResetPassword} id="reset-password-form" autoComplete="off">
            <div className="mb-3">
              <Form.Label htmlFor="exampleInputPassword1" className="form-label">
                <b>New Password</b>
              </Form.Label>
              <Password formId="reset-password-form" />
              <div id="passwordHelp" className="form-text">
                <ul>
                  <li>At least 8 characters</li>
                  <li>At least 1 uppercase letter</li>
                  <li>At least 1 lowercase letter</li>
                  <li>At least 1 number</li>
                </ul>
              </div>
            </div>
            <button type="submit" className="btn btn-primary">
              Submit New Password
            </button>
          </Form>
        )}
      </ModalBody>
    </>
  );
};

export const VerifyEmail = ({ token, showLogo }) => {
  const userData = useSelector((state) => state.userData);
  const [bannerMessage, setBannerMessage] = useState("");

  useEffect(() => {
    const verifyToken = async () => {
      let data = { token: token };
      //console.log("data.token=" + data.token);
      var result = await callAPI("/users/verify", "POST", data, userData, store, false);
      //console.log("result=" + result);
      //console.log("result.status=" + result.status);
      //console.log("result.message=" + result.message);
      //console.log("data.detail=" + result.data.detail);
      if (result.status === "success") {
        setBannerMessage("Your email has been verified. Thank you!");
      } else {
        if (result.apiStatus === 400) {
          if (result.data.detail === "VERIFY_USER_BAD_TOKEN") {
            setBannerMessage("Invalid verify email link or link has expired.");
          } else if (result.data.detail === "VERIFY_USER_ALREADY_VERIFIED") {
            setBannerMessage("Email was previously verified. Thank you!");
          } else {
            setBannerMessage(result.data.detail);
          }
        } else {
          console.error("Error:", result.message);
          setBannerMessage("Error: " + result.message);
        }
      }

    }
    
    verifyToken();
  }, [userData, token]);


  return (
    <>
      {showLogo ? <LogoTitle /> : <></>}
      <ModalHeader>
        <ModalTitle>Verify Email</ModalTitle>
      </ModalHeader>
      <ModalBody>
        {bannerMessage === "" ? (
          <></>
        ) : (
          <Alert className="ms-2 me-2 mt-2" variant="warning">
            {bannerMessage}
          </Alert>
        )}
      </ModalBody>
    </>
  );
};

export const signOut = (onSignOut) => {
  console.log("signing out");
  //reduxStore.dispatch(setUser("", "", "", true))
  store.dispatch(reset());
  console.log("cleared user");
  onSignOut();
};

export const isUserSignedIn = (userData) => {
  return userData.userName !== undefined && userData.userName !== "";
};

export const doesUserHaveRole = (userData, roleName) => {
  const roles = userData.userRoles.split("|");
  return roles.includes(roleName) || roles.includes("SUPERUSER");
};

export const isUserAdmin = (userData) => {
  return !(userData.userRoles === null || userData.userRoles === "");
};

export const rehydrateUserIfChanged = async (store, userData) => {
  const storedState = await getStoredState(persistConfig);
  console.log("storedState.userData.userName=" + storedState.userData.userName);
  if (
    //storedState.userData.userName !== userData.userName ||
    storedState.userData.lastUpdate > userData.lastUpdate
    //storedState.userData.accessToken !== userData.accessToken ||
    //storedState.userData.accessTokenExpires !== userData.accessTokenExpires
  ) {
    console.log("in rehydrateUserIfChanged - rehydrating data");
    store.dispatch(rehydrate(storedState));
    return { ...storedState.userData };
  } else {
    console.log("in rehydrateUserIfChanged - not rehydrating data");
    return { ...userData };
  }
};

export const AccountModal = ({ onClose, onSignIn, onSignUp, onForgotPassword, initialContentType }) => {
  const [contentType, setContentType] = React.useState(initialContentType);

  const switchToSignIn = () => {
    setContentType("signin");
  };

  const switchToForgotPassword = () => {
    setContentType("forgotpassword");
  };

  const switchToSignUp = () => {
    setContentType("signup");
  };

  return (
    <div>
      <div className="d-flex justify-content-end">
        <Button onClick={onClose} variant="link">
          <FaTimes />
        </Button>
      </div>
      {contentType === "signin" ? (
        <SignIn
          onSignIn={onSignIn}
          signUpClick={switchToSignUp}
          forgotPasswordClick={switchToForgotPassword}
          showLogo={true}
        />
      ) : contentType === "signup" ? (
        <SignUp onSignUp={onSignUp} signInClick={switchToSignIn} showLogo={true} />
      ) : (
        <ForgotPassword onForgotPassword={onForgotPassword} signInClick={switchToSignIn} showLogo={true} />
      )}
    </div>
  );
};
